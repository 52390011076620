import React from 'react';
import '../Landing/tachyons.css';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// import LoginPage from '../Login';
// import LandingPage from '../Landing';
// import PasswordResetPage from '../PasswordReset';
// import AboutPage from '../About';
// import PageNotFound from '../PageNotFound';
// import Terms from '../Terms';
// import UpgradePreview from '../UpgradePreview';

// import * as ROUTES from '../../constants/routes';
// import { withAuthentication } from '../Session';

export default () => (
	<div className="mw6 lh-copy center pb4 mt3">
		<div style={{fontSize: '48px', fontWeight: 700}} className="primary">Goodbye for now!</div>
		<div className="mv2 dark-gray f4">
			<p>
				After 80,000 links, 3,000 users, and operating for nearly 4 years LinkDrop will be shutting down February 1st, 2022.
			</p>
			<p>
				It feels weird to get emotional about a bookmarking service, but 
				LinkDrop has been a labor of love since the beginning.
			</p>
			<p>
				As a power user of LinkDrop myself (although not the #1 user) I'll be sad to see it go. 
				To all of the LinkDrop Plus members, I'm forever grateful for your support.
			</p>
			<p>
				If you want to reach out you can message me on <a href="https://twitter.com/jakeshelley">Twitter</a>.
			</p>
			<p>
				Thanks again for everything!
			</p>
			<p>
				- Jake
			</p>
		</div>
	</div>
)

// const App = () => (
// 	<Router>
// 		<Switch>
// 			<Route exact path={ROUTES.LOGIN} component={LoginPage} />
// 			<Route exact path={ROUTES.SIGNUP} component={LoginPage} />
// 			<Route exact path={ROUTES.PW_RESET} component={PasswordResetPage} />
// 			<Route exact path={ROUTES.ABOUT} component={AboutPage} />
// 			<Route exact path={ROUTES.TERMS} component={Terms} />
// 			<Route exact path={ROUTES.UPGRADE_PREVIEW} component={UpgradePreview} />
// 			<Route path={ROUTES.PAGE_NOT_FOUND} component={PageNotFound} />
// 			<Route path={ROUTES.LANDING} component={LandingPage} />
// 		</Switch>
// 	</Router>
// );

// export default withAuthentication(App);
